import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import makeStyles from "@mui/styles/makeStyles"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import { CopyBlock } from "react-code-blocks"
import Chip from "@mui/material/Chip"

import { RepositoryMetrics } from "repository-metrics"

import MenuBookIcon from "@mui/icons-material/MenuBook"
import GitHubIcon from "@mui/icons-material/GitHub"
import ChatIcon from "@mui/icons-material/Chat"
import YouTubeIcon from "@mui/icons-material/YouTube"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import ImageBanner from "../images/universe-4609408.93fbf14b-clean.jpg"
import ImageWavesDark from "../images/bg-waves-dark.png"
import ContentBox from "../components/contentBox"
import AccordionBox from "../components/accordionBox"
import codeBlockTheme from "../theme/codeBlockTheme"
import CodeExampleRestreamer from "../components/codeExampleRestreamer"

import ButtonYellow from "../components/buttonYellow"

import Player from "../components/player"
import RestreamerVideo from "../assets/restreamer.mp4"
import RestreamerPoster from "../assets/restreamer.jpg"

const useStyles = makeStyles(theme => ({
  TabTitel: {
    fontWeight: 500,
  },
  CodeBlock: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: 8,
    width: "100%",
    fontSize: "1rem",
  },
  CodeTab: {
    padding: "10px 0px 5px 0px",
  },
}))

function DockerCmd() {
  const classes = useStyles()
  const [valueDockerCmd, setValueDockerCdm] = React.useState("1")

  const handleChangeDockerCmd = (event, newValue) => {
    setValueDockerCdm(newValue)
  }

  return (
    <Box sx={{ width: "100%", marginBottom: "-.5em", paddingRight: ".4em" }}>
      <TabContext value={valueDockerCmd}>
        <Box sx={{ borderBottom: 2, borderColor: "rgba(0,0,0,.45)" }}>
          <TabList
            onChange={handleChangeDockerCmd}
            aria-label="docker-tab"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab label="AMD64/ARMv7/ARM64" value="1" />
            <Tab label="Raspberry Pi (ARMv7/v8)" value="2" />
            <Tab label="NVidia CUDA (AMD64)" value="3" />
            <Tab label="Intel VAAPI (AMD64)" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_multiarch}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> to allow access to connected devices
              on the host system.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="2" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_pi}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> to allow access to connected devices
              on the host system.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="3" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_cuda}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> to allow access to connected devices
              on the host system.
            </Typography>
          </Grid>
        </TabPanel>
        <TabPanel value="4" className={classes.CodeTab}>
          <Grid container spacing={0}>
            <div className={classes.CodeBlock}>
              <CopyBlock
                text={CodeExampleRestreamer.docker_vaapi}
                language="bash"
                theme={codeBlockTheme}
              />
            </div>
            <Typography
              variant="body"
              fontSize=".8rem"
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
            >
              <strong>--privileged</strong> to allow access to connected devices
              on the host system.
            </Typography>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

function Datasheet() {
  const classes = useStyles()
  const [valueDatasheet, setValueDatasheet] = React.useState("1")

  const handleChangeDatasheet = (event, newValue) => {
    setValueDatasheet(newValue)
  }

  return (
    <Box sx={{ width: "100%", marginBottom: "-.5em", paddingRight: ".4em" }}>
      <TabContext value={valueDatasheet}>
        <Box sx={{ borderBottom: 2, borderColor: "rgba(0,0,0,.45)" }}>
          <TabList
            onChange={handleChangeDatasheet}
            aria-label="datasheet-tab"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab label="Video sources" value="1" />
            <Tab label="Video targets" value="2" />
            <Tab label="Media servers" value="3" />
            <Tab label="Playback" value="4" />
            <Tab label="API" value="5" />
            <Tab label="Miscellaneous" value="6" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Network Video
            </Grid>
            <Grid item xs={12} md={9}>
              RTP, RTMP/S, RTSP/S, HTTP/S, SRT
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              USB video device
            </Grid>
            <Grid item xs={12} md={9}>
              Linux (MacOS, Windows with Docker Desktop/Linux-Virtualization)
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Raspberry Pi camera module
            </Grid>
            <Grid item xs={12} md={9}>
              Raspberry Pi3 and later
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Social media platforms
            </Grid>
            <Grid item xs={12} md={9}>
              YouTube Live, Facebook Live, Twitter, LinkedIn, TikTok, more …
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Streaming Server
            </Grid>
            <Grid item xs={12} md={9}>
              Wowza Streaming Engine, Red5, Nimble Streamer, Ant Media Server
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Network Endpoints
            </Grid>
            <Grid item xs={12} md={9}>
              RTP, RTMP/S, RTSP/S, HTTP/S, SRT
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              HLS Server (HTTP/S)
            </Grid>
            <Grid item xs={12} md={9}>
              Configurable chunk and playlist sizes
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              RTMP/S Server
            </Grid>
            <Grid item xs={12} md={9}>
              Configurable app endpoint and security token (retrieve and
              receive)
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="4">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Videoplayer
            </Grid>
            <Grid item xs={12} md={9}>
              Integrated Video.js player
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Playersite
            </Grid>
            <Grid item xs={12} md={9}>
              Selfhosting ready-made HTML landing page
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="5">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Process
            </Grid>
            <Grid item xs={12} md={9}>
              FFmpeg input and output configuration, limits, cleanup, progress-
              and metadata, states and historical logs
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Capabilities
            </Grid>
            <Grid item xs={12} md={9}>
              List all detected FFmpeg capabilities
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Metrics
            </Grid>
            <Grid item xs={12} md={9}>
              Query the collected metrics in configurable intervals
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Session
            </Grid>
            <Grid item xs={12} md={9}>
              Get a summary of all active and past sessions
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              In-Memory / Disk Filesystem
            </Grid>
            <Grid item xs={12} md={9}>
              Manage file assets
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              RTMP
            </Grid>
            <Grid item xs={12} md={9}>
              List all currently publishing streams
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Config
            </Grid>
            <Grid item xs={12} md={9}>
              Application configuration
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Log
            </Grid>
            <Grid item xs={12} md={9}>
              Get the last log lines of the application
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="6">
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Bandwidth and viewer control
            </Grid>
            <Grid item xs={12} md={9}>
              Limitations for HTTP bandwidth (Mbit/s) and active HTTP sessions
              (currenct viewer)
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              HTTPS/TLS
            </Grid>
            <Grid item xs={12} md={9}>
              Let’s Encrypt support to enable HTTPS/RTMPS
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Video licensing
            </Grid>
            <Grid item xs={12} md={9}>
              Creative Commons
            </Grid>
            <Grid item xs={12} md={3} className={classes.TabTitel}>
              Multi language
            </Grid>
            <Grid item xs={12} md={9}>
              Use your own native language, or support multiple differen
              languages with ease.
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
const IndexPage = () => (
  <Layout>
    <Seo
      title="Open Source Streaming Server ⇒ Selfhosting Video Server"
      description="Video streaming on websites ⇒ without streaming provider ✓ Open Source ✓ Free ✓ All IP cams ✓ Raspberry Pi ✓ All operating systems ⇒ learn more!"
      keywords="open-source, streaming, server"
      lang="en"
    />
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#fff url(${ImageBanner}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <Header siteName="home" rootPath="/" />
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          marginTop={10}
          marginBottom={10}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            width={{ xs: "100%", md: "100%" }}
          >
            <Typography variant="h1" fontSize={{ xs: "3rem", sm: "4rem", md: "5rem" }}>
              RESTREAMER
            </Typography>
            <Typography variant="body" fontSize="2rem">
              An easy to use open source video server with fancy user interface
              and FFmpeg API for free video streaming
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <RepositoryMetrics
                owner="datarhei"
                repo="restreamer"
                theme="dark"
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{
                  border: "1px solid #b2bec3",
                  borderRadius: 5,
                  padding: "5px 8px 5px 8px",
                  background: "#394144",
                }}
              >
                <MuiLink
                  href="https://github.com/datarhei/restreamer/blob/2.x/LICENSE"
                  target="_blank"
                  aria-label="Apache 2.0 license"
                  fontSize=".95rem"
                  color="#dfe6e9"
                >
                  Apache 2.0 license
                </MuiLink>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            width="100%"
          >
            <Player
              source={RestreamerVideo}
              poster={RestreamerPoster}
              type="video/mp4"
              control={true}
            />
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          marginBottom={11}
        >
          <ContentBox
            titel="Multi Streaming"
            description="Stream video live to any video platform in the universe or your website!"
            variant="teaser"
          />
          <ContentBox
            titel="Easy Configuration"
            description="Wizard helps to configurate for complex settings in everyday work."
            variant="teaser"
          />
          <ContentBox
            titel="No License Costs"
            description="Restreamer ist open-source software and free for private and commercial use."
            variant="teaser"
          />
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            id="get-started"
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            INSTALLATION WITH DOCKER
          </Typography>
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            Multiarch CPU and GPU! The Restreamer Docker image is easy to
            install and runs on Linux, macOS, and Windows, as well as on
            Raspberry Pi and single-board computers. The configuration is
            straightforward with a few simple steps. Finally, a live stream is
            ready in a minute for websites, an external streaming service,
            social networks, YouTube, or directly on the finished internal micro
            page!
          </Typography>
          <ContentBox maxWidth={1000} description={<DockerCmd />} />
          <Stack paddingTop={5} paddingBottom={1}>
            <Typography
              variant="h3"
              fontSize="2rem"
              color="#333"
              fontWeight={900}
            >
              DOCS & HELP
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            alignItems="center"
            spacing={{ xs: 2, md: 8 }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://docs.datarhei.com/restreamer"
                marginBottom={-1.5}
                aria-label="Manual"
              >
                <MenuBookIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://docs.datarhei.com/restreamer"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Manual
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://www.youtube.com/user/datarhei"
                marginBottom={-1.5}
                aria-label="Video Guides"
              >
                <YouTubeIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://www.youtube.com/user/datarhei"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Video Guides
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://github.com/datarhei/restreamer/issues"
                marginBottom={-1.5}
                aria-label="Ticket/Issue"
              >
                <GitHubIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://github.com/datarhei/restreamer/issues"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Ticket/Issue
              </MuiLink>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiLink
                href="https://discord.gg/33JCDkPmmj"
                marginBottom={-1.5}
                aria-label="discord Chat"
              >
                <ChatIcon sx={{ color: "#333", fontSize: 60 }} />
              </MuiLink>
              <MuiLink
                href="https://discord.gg/33JCDkPmmj"
                style={{ color: "#333" }}
                fontSize="1.2rem"
                fontWeight={600}
              >
                Discord Chat
              </MuiLink>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#222`,
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} textAlign="center" marginBottom={2}>
            <Typography
              id="features"
              variant="h2"
              fontSize="2.5rem"
              color="#fff"
              fontWeight={900}
            >
              FEATURES
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center" marginBottom={{xs: 3, md: 10}}>
            <Typography
              id="features"
              variant="body"
              fontSize="1.2rem"
              color="#fff"
              fontWeight={300}
            >
              The Restreamer is an entire streaming server solution for
              self-hosting. Upload your live stream to YouTube, Twitch,
              Facebook, Vimeo, or other streaming solutions like Wowza. Receive
              video data from OBS and publish it with the internal RTMP server.
              The pretty and straightforward user interface is fun to use and
              easy to understand. Beginners are supported by assistance and test
              systems and immediately find their way around the complexity of a
              video server. Professionals appreciate the fast and spontaneous
              configuration options for complex settings of the applications in
              everyday work. In addition, the Bandwidth limitation helps
              calculate the traffic costs to reduce financial and technical
              risks. It is GDPR compliant without third-party providers and no
              saving audience data. 100 % control over the video data at any
              time. And the best thing is that Restreamer is open-source
              software, has no ongoing license costs, and is free for private
              and commercial use!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center" marginBottom={{xs: 2, md: 0}}>
            <StaticImage
              src="../images/restreamer-clean-interface.png"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Shows the Restreamer UI landingpage."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Clean User Interface
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              The Restreamer offers a simplified admin panel for streaming
              server newcomers or for users for whom the range of functions of a
              streaming server is simply too large. The GUI has a clear menu
              navigation, but also a start screen level with the most important
              elements. Direct feedback in the user interface with error
              descriptions speeds up configuration and avoids misconfigurations.
              In addition, the expert mode extends the range of functions to
              meet the requirements of professionals.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Multiple channels" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Build-in video player" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Stream statistics" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Viewer statistics" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bandwidth information" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="System monitoring" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Publication website" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Output control" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Expert mode" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Multiple Video Sources
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              The Restreamer can manage various video sources from USB, network,
              or virtual. In addition, the built-in RTMP server processes video
              signals from external software such as OBS or hardware video
              encoders from Blackmagic and Elgato. Finally, as an absolute
              highlight, Restreamer supports low latency video with an internal
              SRT server.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="IP Camera" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="USB Camera" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RaspiCam" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="OBS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Blackmagic" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Video Software" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Hardware Encoder" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="WOWZA" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HLS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DASH" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <StaticImage
              src="../images/restreamer-multiple-sources.png"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Shows the setup of a video source."
              width={500}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6} textAlign="center" marginBottom={{xs: 2, md: 0}}>
            <StaticImage
              src="../images/restreamer-multi-streaming.png"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Shows a selection of possible video targets."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Multi Streaming
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              With Publication Services, the Restreamer can stream video
              anywhere. There are many modules available for popular video
              platforms and social networks. The various video protocols support
              transmission to broadcast stations, video software, or hardware.
              In addition, integrating video over a fully documented API allows
              video streams in your applications with the simplest means without
              extensive software development.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="YouTube" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Social media" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="CDN" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HLS" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DASH" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="API" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Red5" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="WOWZA" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Ant Media" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Open-Source Video-Player
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              Video.js is the default player in the Restreamer and a free,
              open-source web player used by professionals worldwide. It
              supports HTML5 video and all modern streaming formats. Many HLS
              players, such as JW Player, Bitmovin, Clappr, or the Theo Player,
              can be used in the Restreamer. As a unique feature, the datarhei
              Restreamer exclusive CC plugin is ready to publish a license
              directly in the video stream, granting the public various usage
              rights to the work.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Creative Commons license" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="IMA plugin" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="CSS styling" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="API" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Open-source libary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="JW-Player" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bitmovin" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center" marginBottom={{xs: 2, md: 0}}>
            <StaticImage
              src="../images/restreamer-player.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Shows the configurator for the video player."
              width={500}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={{xs: 4, md: 10}}
        >
          <Grid item xs={12} md={6} textAlign="center" marginBottom={{xs: 2, md: 0}}>
            <StaticImage
              src="../images/restreamer-tons-of-functions.png"
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Shows the many setting options and the process details."
              width={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              fontSize="1.6rem"
              fontWeight={600}
              maxWidth="50%"
            >
              Tons of functions
            </Typography>
            <Typography variant="body" fontSize="1rem" maxWidth="50%">
              The Restreamer contains many neat functions that make everyday
              streaming easier and significantly expand the application
              possibilities of the program. Log files help analyze errors,
              monitor proper operation, or improve systems. In addition, log
              files obtain information about video processing. The data can
              adapt and optimize the video stream.
            </Typography>
            <Grid container spacing={1} marginTop={1}>
              <Grid item>
                <Chip label="Multi language" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Wizard system" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Debug report" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="System logging" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HDMI" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="DVR" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Refferer banlist" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Expert mode" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Mux audio" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Video snapshot" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="HTTPS Let's Encrypt" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="User limitation" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Bandwith control" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP security token" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="Storrage support" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="GPU encoding" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTMP server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="SRT server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="RTSP server" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label="FFmpeg REST API" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            id="demo"
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            RESTREAMER DEMO
          </Typography>
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            <strong>Username:</strong> admin <strong>Password:</strong> demo
          </Typography>
          <ButtonYellow
            variant="form_dark"
            titel="Open Demo"
            href="https://demo.datarhei.com/ui"
            target="blank"
          />
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: "#333",
        borderTop: "3px solid #111",
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} textAlign="center" marginBottom={10}>
            <Typography
              variant="h2"
              fontSize="2.5rem"
              color="#fff"
              fontWeight={900}
            >
              INSIDE RESTREAMER
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} textAlign="center">
            <StaticImage
              src="../images/restreamer-stack.png"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Restreamer Interface Screens"
              width={1000}
            />
          </Grid>
          <Grid item xs={12} textAlign="center" marginTop={5}>
            <Typography
              variant="body"
              fontSize="1.2rem"
              color="#fff"
              fontWeight={300}
            >
              datarhei Restreamer is the central interface for mapping AV
              processes. It is responsible for the design and management and
              provides all necessary interfaces to access the video content. The
              control for FFmpeg can keep all used functions reliable and
              executable without the need for software developers to take care
              of it. In addition, process and resource limitation for all FFmpeg
              processes protects the host system from application overload. The
              overall system gives direct access to current process values (CPU,
              RAM) and complete control of system resources and system load with
              statistical access to process data and current and historical
              logs.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#EAEA05 url(${ImageWavesDark}) no-repeat right center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={9}
      paddingBottom={9}
    >
      <Container maxWidth="xl" style={{ textAlign: "center" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="h2"
            fontSize="2.5rem"
            color="#333"
            fontWeight={900}
          >
            DATASHEET
          </Typography>
          <ContentBox maxWidth={1000} description={<Datasheet />} />
          <Typography
            variant="body"
            fontSize="1.2rem"
            color="#333"
            fontWeight={300}
          >
            The datarhei Restreamer is a complete streaming server solution for
            self-hosting and offers smart free video streaming in real time.
          </Typography>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        background: `#fff url(${ImageBanner}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
      paddingTop={10}
      paddingBottom={10}
    >
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          paddingBottom={5}
        >
          <Typography
            id="faq"
            variant="h2"
            fontSize="2.5rem"
            fontWeight={900}
            textAlign="center"
            textTransform="uppercase"
          >
            Frequently Asked Questions
          </Typography>
        </Stack>
        <AccordionBox
          titel="What is the streaming server datarhei Restreamer?"
          description="The Restreamer is a complete streaming server solution to host yourself. 
              Upload your live stream to YouTube, Twitch, Facebook, Vimeo, or other streaming 
              solutions like Wowza. Receive video from OBS and publish it via the internal RTMP server. 
              A visually appealing user interface, no ongoing licensing costs. Complete control over 
              video data at all times. Bandwidth limitation helps calculate traffic costs to reduce 
              financial and technical risks. It is GDPR compliant with no third-party providers and no 
              storage of viewer data. The Restreamer Docker image is easy to install. It runs on Linux, 
              macOS, Windows, Raspberry Pi, and single-board computers. Beginners are supported by help 
              and test systems and immediately find their way around the complexity of a video server. 
              Professionals appreciate the fast and spontaneous configuration options for complex 
              settings of applications in everyday work."
        />
        <AccordionBox
          titel="What operating system is required to install the datarhei Restreamer streaming server?"
          description="We recommend our official Docker image for a fast and secure installation. Once Docker 
              is running on the host, the chances of using the Restreamer are excellent. The Docker Engine is 
              available on various Linux platforms, macOS, and Windows 10 via Docker Desktop and as a static binary installation."
        />
        <AccordionBox
          titel="What are the requirements for using datarhei Restreamer as a streaming server?"
          description="We have a set of minimum requirements to receive official support from our team. These requirements 
              are necessary for us to access essential system information, provide an SLA, answer questions, or give a solution to 
              the problem. We cover only installations that meet these minimum requirements by our SLAs and paid support policy. It 
              is necessary to use our official Docker images. It avoids issues due to missing, outdated, or mismatched dependencies, 
              specific operating system issues, or issues with manual installations."
        />
        <AccordionBox
          titel="Can I activate a live stream on the streaming server without any know-how?"
          description="The configuration via the graphical user interface in a browser is easy. Then, without any experience, 
              a wizard helps you create a new video source. Then, the video signal is live via the integrated publication service in 
              just a few steps, e.g., on YouTube, on the internal player page, or your website."
        />
        <AccordionBox
          titel="How many steps are required to install the streaming server?"
          description={
            <React.Fragment>
              It is installed and started on the host system in a few seconds.
              The graphical user interface is immediately ready for the first
              login.
              <br />
              <br />
              Prerequisites are a host system with Docker, an internet line for
              the Docker pull, and a browser.
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Is the streaming server ready for the Raspberry Pi?"
          description={
            <React.Fragment>
              Suppose there is a perfect streaming server for the popular
              single-board computer Raspberry Pi. In that case, it is the
              datarhei Restreamer! We recommend a Raspberry Pi 4. It works from
              the Raspberry Pi 2 and on a Raspberry Pi Zero. Due to the onboard
              GPU, the Raspi 4 is best suited for video streaming with a video
              streaming server. There are, of course, many other great single
              board computers like ODROID, pine64, the Hummingboard, and many,
              many more. The necessary system requirements are straightforward.
              Once Docker is working, you will be able to install the datarhei
              Restreamer and use it for live streaming. You can find the
              requirements in the <a href="https://docs.datarhei.">Docs</a>.
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="What components provide the open-source streaming server?"
          description="The datarhei Restreamer streaming server consists of a few arranged modules. There are three main components: input 
              signal, processing, output, all with various system settings. The main screen always displays the current video channel with telemetry 
              data in real-time."
        />
        <AccordionBox
          titel="What are the advantages of the open-source streaming server datarhei Restreamer?"
          description="It is possible to use live video streaming in real-time on websites and social media platforms without an additional 
              streaming provider for video hosting – all without monthly license costs for streaming software or the required bandwidth.              
              The datarhei Restreamer is a modern open-source video streaming platform that anyone can install. Videos from webcams to professional 
              television programs can be reliably transmitted live without a video hoster. datarhei helps private and professional video creators to 
              stream more efficiently. The Restreamer is a simple but powerful video streaming server. The fully documented API allows for software 
              development. Therefore, it is straightforward to adapt to any project."
        />
        <AccordionBox
          titel="When is the integration of a streaming server like the datarhei Restreamer helpful?"
          description="Whenever live and video is a requirement, streaming servers are needed. The output is then referred to as a live stream. Streaming servers are designed to broadcast videos to large audiences. A streaming server is very similar to a web server, except that websites are not delivered but videos. It is also usable for video clips (VoD). The streaming server datarhei Restreamer is a frontend module for the datarhei Core. The Core is written in Go and can easily be integrated by software developers into programs or administrators into system landscapes to fulfill its part in transporting the video data. Use is free for personal and commercial applications."
        />
        <AccordionBox
          titel="Is the streaming server Restreamer open-source software?"
          description="datarhei Core and the Restreamer are open-source software. The source code is available on the GitHub software platform. Software developers can make adjustments or add their modules. FOSS GmbH from Switzerland is behind the software. Because we know our software best, we can provide support for enterprise applications."
        />
        {/* <AccordionBox
          titel="Which protocols are supported by the open-source video streaming server Restreamer?"
          description="By using FFmpeg in the datarhei Core, the streamer can use all protocols from FFmpeg. However, we have limited the active logs to a reasonable range. The protocols work without major adjustments: Apple HLS, RTSP, RTMP(E, S, PT, TE, TS), SRT, MPEG-TS, UDP, Framebuffer (HDMI), and Raw."
        />
        <AccordionBox
          titel="What video formats can the streaming server manage?"
          description="The Restreamer can receive and output all formats from FFmpeg via the datarhei Core. That's over 50 different formats! However, we have tuned the system to work with the usual industry standards for video (H.264 (x264), H.265 (x265), VP9), and audio (AAC, MP3, Opus). With a bit of configuration, you can use more formats on the Restreamer. For this, you have to recompile FFmpeg with the required formats."
        />
        <AccordionBox
          titel="How can the streaming server Restreamer record and store video clips (Video on Demand)?"
          description="There are various options for saving video clips (VoD) in the system settings. In addition, the streaming server supports local storage or network drives."
        />
        <AccordionBox
          titel="Is datarhei's streaming open-source streaming server ready for DVR?"
          description="DVR stands for Digital Video Recorder and is a particular type of storage. If the audience missed something, they could rewind it in datarhei Restreamer's player. How long this is possible depends on the system settings. The videos are stored in RAM. As a result, the bigger the RAM, the longer it is possible to keep the video. How long the memory does not fill up depends on the bit rate of the video stream. The values are adjusted in the system settings for the host system."
        />
        <AccordionBox
          titel="Is the video player included in the datarhei streaming server?"
          description="The Restreamer comes with an HTML5 video player that everybody can use immediately. It is the world's most popular open-source HTML5 player Video.js. With a simple configuration in the Restreamer, everyone can switch to their favorite player."
        />
        <AccordionBox
          titel="Can I use datarhei's streaming server without special skills?"
          description="When developing the Restreamer, we placed great value on simplicity and clarity. Assistants and test systems support beginners. As a result, everyone can immediately find their way around the complexity of a video server."
        />
        <AccordionBox
          titel="Can I do live streaming for free with datarhei's streaming server?"
          description="Our promise is live streaming on websites without additional Service providers, like a video hosting provider. This is available instantly, free of charge, and without advertising. Of course, you have to provide the hardware for the installation, the internet line, and the video signal."
        />
        <AccordionBox
          titel="Is free HTTPS available with Let's Encrypt on streaming server datarhei Restreamer?"
          description="With just a few clicks, the switch to HTTPS is done. We've made the process extremely straightforward. You'll find a quick guide on YouTube and instructions in the manual. In a nutshell. Enter domain - forward SSL ports - enable hook - save - done!"
        />
        <AccordionBox
          titel="How can I give a license with usage rights to a live stream on the streaming server?"
          description="The datarhei open-source streaming server Restreamer has something unique that no other streaming server offers. Each video signal can be tagged with one of the standard Creative Commons (CC) license agreements. This allows video producers to easily grant official usage rights to live streams to the public. Free content is created. Free content is essential for people who can't or don't want to spend money on text, images, music, and other things - or who want to make money from it. Under specific CC licenses, the content may be changed and further processed."
        />
        <AccordionBox
          titel="What is unique about datarhei's streaming server Restreamer?"
          description={
            <React.Fragment>
              In many ways, the streaming server datarhei Restreamer is
              different from other streaming servers and, therefore, something
              special. Core and Restreamer are not based on other existing
              products but have been completely redeveloped with every line of
              programming code over the years by streaming specialists from
              Germany and Switzerland. Depending on the application, different
              points are particularly noticeable.
              <br />
              <br />
              <strong>En-users</strong> of the Restreamer who need a simple
              streaming server will appreciate the straightforward installation
              of the Docker image, the simple user interface, and assistance
              systems. Small niceties like the Creative Commons licenses and
              free HTTPS with Let's Encrypt and the ready-made landing page with
              the video player keep the hurdles very low. Complicated settings
              are not necessary to start streaming directly!
              <br />
              <br />
              <strong>Professional</strong> end users appreciate the wide range
              of functions of the streaming server. The expert mode expands the
              streaming server with everything a professional expects from a
              streaming server in everyday live streaming! In addition,
              interfaces via SRT and HDMI allow the use of professional
              broadcasting.
              <br />
              <br />
              <strong>Administrators</strong> are modern mechanics. Like
              mechanics in the garage, they like the Restreamer because of the
              datarhei Core - the engine of the datarhei Restreamer.
              Platform-independent integration on the server is easy with the
              Docker image and scalable to almost any size. The possibility to
              use existing monitoring systems and to activate secure logon
              procedures (SSO) via Auth0 are clear signals that speak for the
              datarhei Restreamer! In addition, the open-source license model
              reduces the running costs to a minimum. <br />
              <br />
              <strong>Software developers</strong> love datarhei Core's FFmpeg
              API and Restreamer's REACT frontend. The Core is the engine for
              the streaming server. It enables simple, reliable, and secure
              software development for any type of video software. <br />
              <br />
              No matter how you use the Core or Restreamer. The support of FOSS
              GmbH offers reliability and security for all professional
              requirements in the enterprise sector.
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Does Restreamer have anything to do with restream.io?"
          description="datarhei.com products and datarhei Restreamer have no relation to restream.io. However, the web service restream.io was founded a few years after datarhei. It is documented in the repo at GitHub, and the registration date of the domains www.datarhei.com and datarhei.org and .io."
        />
        <AccordionBox
          titel="Does datarhei's streaming server Restreamer work with OBS?"
          description="Using OBS with the Restreamer is quite simple with the internal RTMP server function. OBS serves as the sender for the RTMP stream and the Restreamer as the receiver. We have a quick guide on our YouTube channel. It takes just a few clicks to configure. Best of all, the feature can be used to receive any RTMP-enabled video source. OBS is just one of very many possibilities for an application."
        />
        <AccordionBox
          titel="How many steps are required to integrate a live stream on a social media platform?"
          description="A video signal can be transmitted to any streaming platform with the datarhei Restreamer. In addition, publication services are available for social streaming. Depending on the target platform, only 2 to 5 clicks are necessary until the live stream is online on the social media platform."
        />
        <AccordionBox
          titel="Is datarhei Restreamer a real streaming server for live streaming?"
          description="The datarhei.com Restreamer is a real streaming server that meets all the needs for live streaming on the web. Our roadmap still has a few points open. However, the most critical thing for servers is stability. Because our server has been tested hundreds of thousands of times, we meet this requirement with distinction! The streaming server runs and runs and runs!"
        />
        <AccordionBox
          titel="How can I live stream to YouTube for free with the streaming server?"
          description="With just a few clicks, you can transfer your video signal to YouTube via the Publication Service in the Restreamer. It is really effortless - and we mean very effortless! In short: Choose Publication Service > Insert Streaming Key from YT > Start Stream on YT!
              We have produced a short video with a quick guide: https://www.youtube.com/watch?v=dwywuaIQPRg "
        />
        <AccordionBox
          titel="Can I live stream a Raspberry Pi Cam with the streaming server?"
          description="The video module for the well-known Raspberry Pi devices is called Raspicam. The cam is ready for live streaming after installing in the raspi-config menu. As soon as the camera module is active on the Pi, it is detected by the Restreamer and immediately ready for live streaming. If you want to know how easy it is to use the Raspicam for live streaming, check out this quick guide to see for yourself. https://youtu.be/ahawoTRDLEw "
        />
        <AccordionBox
          titel="How can I live stream an IP cam with the streaming server Restreamer?"
          description="There is nothing better for the Restreamer than broadcasting a network camera live. The requirements for transmitting an IP cam video stream are the RTSP or RTMP protocol and the video signal with the codec H.264 or H.265. One of the two protocols and one of the codecs are available for all manufacturers of surveillance cameras. You just need to know the streaming address for your camera model to use the IP cam as a video source with the streaming server. The setup is done comfortably via the wizard and is very  easy. You can find a quick guide on our YouTube channel: https://youtu.be/faH_X1i_Q00"
        />
        <AccordionBox
          titel="What is the landing page with the video player?"
          description="For an easy and fast presentation of the live stream, the datarhei Restreamer comes with a ready-made website. The page is equipped with a video player and ready to go. The index.html can be linked or distributed. All elements of the website are customizable via the menu. Advanced users with programming knowledge can add their CSS styles and interactive widgets via HTML code injection."
        />
        <AccordionBox
          titel="How can I connect HDMI devices to the streaming server?"
          description="The use of HDMI video is possible.The prerequisite for HDMI is a native installation. At the start of the Restreamer V2, this type of installation is not yet officially supported by us - but it is already possible. As soon as we officially support native installation, you will find a quick guide on our YouTube channel and the manual. Follow our Twitter channel or subscribe to our newsletter to not miss the news! If you need HDMI for your project right now, get in touch with us."
        />
        <AccordionBox
          titel="Can I use GPU encoding with the streaming server Restreamer?"
          description={
            <React.Fragment>
              GPU (Graphics Processing Unit) encoding is tremendous and
              supported in expert mode. There are several exciting applications
              for GPU encoding. The graphics processor (GPU) takes over
              computationally intensive tasks and thus relieves the central
              processor unit (CPU). The function is directly controlled in the
              Restreamer via the Core with FFmpeg. The freed processor time can
              be used for other tasks by the CPU. We have a quick tutorial on
              our datarhei YouTube channel that shows one of many applications
              using a Raspberry PI GPU. <br />
              <br />{" "}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/yPy3ElSkoSA"
                title="quickstart GPU encoding streaming server"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </React.Fragment>
          }
        />
        <AccordionBox
          titel="Is the commercial use of the streaming server datarhei Restreamer allowed?"
          description="Do what you want with your datarhei Restreamer. Everything is allowed — private and commercial. If your company implements a project with the datarhei, you are welcome to contact us and say: Hello!. We appreciate every reference and support some projects with a case study, a backlink to the project and the website, or at least a small social media post on Twitter."
        />
        <AccordionBox
          titel="Can I use audio for my live stream on the streaming server?"
          description="Each video channel in the Restreamer is always audio capable. Any audio format can be muxed (mixed). Audio is queried by the assistance system and can also be edited later. If an audio channel without sound is required, it can be assigned silence. This can be necessary for YouTube, for example. The audio channel can, of course, also be deactivated."
        /> */}
      </Container>
    </Stack>
  </Layout>
)

export default IndexPage
